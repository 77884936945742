<template>
<div>
    <div v-for="liste in gruplar" :key="liste.id">
        <div v-for="altgrup in liste" :key="altgrup.id">

            <a class="text-dark" :href="$router.resolve({name:'AltgruplarPage', params : {id:altgrup.id, g_ad:altgrup.ad.replace(/\//g, '')} }).href">
 
           <!-- <router-link :to="{ name : 'AltgruplarPage', params : {id:altgrup.id, g_ad:altgrup.ad.replace(/\//g, '')} }"> -->
               
                    <p class="card-text urun-gruplari-each mt-2 mb-2" :class="urunGruplariEach">{{ altgrup.ad }}</p>
                </a>
          <!--  </router-link> -->
            <router-view> </router-view>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import axios from 'axios'

export default {
    data() {
        return {
            gruplar: [],
            showYukleniyor: true,
            showUrunGruplari: false,
            errors: [], // hata olursa buraya doner

        }
    },
    components: {

    },

    created() { // sayfa render oldugu zaman ilk calisacak metod burasi
        this.fetchGruplar()
    },

    computed: {

        ...mapGetters([  "urunGruplariEach", "serverRequest"])

    },

    methods: {

        async fetchGruplar() { // urun gruplari icin

       

            this.getUid_Udep()
            this.getKoordinat()

            try {
                const formData = new FormData();
                formData.append('f', 'get_gruplar');
                formData.append('deposahip', this.ushp);
                const response = await axios.post(this.serverRequest, formData, {
                    headers: {
                        // 'Accept': 'application/json',
                        // "Access-Control-Allow-Origin": "*",
                        // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                });
                this.gruplar = response.data // data() icerisinde de tanimlanan altgrupUrunler array-ine request sonrasi donen bilgiler yazilir
          
            } catch (error) {
                this.errors.push(error) // hata mesajlari data() icerisinde de tanimlanan error array-ine yazilir
            }
            if (this.gruplar == "") {
                this.showYukleniyor = false
            }
            // console.log("sorgu yapildi")
        },

        getUid_Udep() {
            this.udep = this.decryptCookie("udep")
            this.ushp = this.decryptCookie("ushp")
        },
        getKoordinat() {
            this.koordinat = localStorage.getItem("koord")
        },

    }
}
</script>

<style scoped>
[v-cloak]>* {
    display: none;
}

</style>
